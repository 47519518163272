import React from 'react';
import { motion } from 'framer-motion';
import './services.css'
import { fadeIn, staggerContainer, } from '../../utils/motion';

import { Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/keyboard';

const Services = () => {


  return (
    <>
     <motion.section 
     initial="hidden"
     whileInView="show"
     variants={staggerContainer} 
     viewport={{once: false, amount: 0.25}}
     className='service-section' id='services'>
        <div className='container'>
            <h2 className='title'>Services</h2>

            {/*<motion.div
            
            className='text-image'>
                

                <div className='s_image-div'>
                <motion.div
                variants={fadeIn("right", "tween", 0.2, 1)} 
                className='text-div'>
                    <h3>We’ve Built Innovative <br/>Applications for Clients<br/> like you</h3>
                    <p>We help our clients leverage the power of technology to solve their business challenges. Our team of skilled developers has experience working with the variety of industries and has a proven track record of delivering high-quality software products. </p>
                </motion.div>
                </div>
            </motion.div>*/}

            <div class="s_service_flex">
            <div 
            className='s_service-list s_left-content'>
                <motion.div
                variants={fadeIn("right", "tween", 0.2, 1)} 
                className='s_text-div'>
                    {/*<h3>We help our clients leverage the power of technology to solve their business challenges with our services</h3>*/}
                    <p>We help our clients leverage the power of technology to solve their business challenges with our services. </p>
                </motion.div>
            
            <motion.div
            variants={fadeIn("up", "tween", 0.2, 0.6)} 
            className='s_service-item'>
                <img src='images/coding.png' alt='#' />
                <h4>Web development</h4>
            </motion.div>

            <motion.div 
            variants={fadeIn("up", "tween", 0.4, 0.6)} 
            className='s_service-item'>
                <img src='images/mobile-app.png' alt='#' />
                <h4>Mobile app development</h4>
            </motion.div>

            <motion.div 
            variants={fadeIn("up", "tween", 0.6, 0.6)} 
            className='s_service-item'>
                <img src='images/ecommerce.png' alt='#' />
                <h4>Ecommerce Services</h4>
            </motion.div>

            <motion.div
            variants={fadeIn("up", "tween", 0.8, 0.6)} 
            className='s_service-item'>
                <img src='images/mortarboard.png' alt='#' />
                <h4>Academy</h4>
            </motion.div>

            <motion.div
            variants={fadeIn("up", "tween", 1, 0.6)} 
            className='s_service-item'>
                <img src='images/wrench.png' alt='#' />
                <h4>Maintainance and support</h4>
            </motion.div>

            <motion.div
            variants={fadeIn("up", "tween", 1.2, 0.6)} 
            className='s_service-item'>
                <img src='images/consulting.png' alt='#' />
                <h4>Consultancy</h4>
            </motion.div>
              
            </div>

            <div class="s_right-content">
                <img src="images/service-right.jpg" alt="service" /> 
            </div>
            </div>

            <div className='academics-div'>
                <h3>Explore our Academics</h3>
                <p>Our software academy is an institution that offers education and training in software development. These programs are typically designed to teach students the skills and knowledge they need to become professional software developers.
                    Upon completion of any course taken with us, Learners will receive a certificate of completion, which can be used to demostrate their skills and knowledge to potential employers.
                </p>

                {/*<div className='courses-div'>
                    <div className='course-list'>
                        <div className='course'>
                            <img src='images/html.jpg' alt='#' />
                             <p>HTML/CSS</p>
                        </div>

                        <div className='course'>
                            <img src='images/html.jpg' alt='#' />
                             <p>HTML/CSS</p>
                        </div>

                        <div className='course'>
                            <img src='images/html.jpg' alt='#' />
                             <p>HTML/CSS</p>
                        </div>

                        
                    </div>
        </div>*/}

                <div className='course-slide-div'>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={3}
                        breakpoints={{
                            // when window width is >= 640px
                            320: {
                              spaceBetween: 20,
                              slidesPerView: 1,
                            },
                            // when window width is >= 768px
                            768: {
                              spaceBetween: 20,
                              slidesPerView: 3,
                            },
                          }}
                          navigation
                        effect="fade"
                        keyboard
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        
                        >
                        
                        <SwiperSlide>
                            <div className='course-slide'>
                            <img src='images/html-css.jpg' alt='#' />
                             <p>Learn Web design with HTML/CSS</p>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='course-slide'>
                            <img src='images/javascript.jpg' alt='#' />
                             <p>Javascript</p>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='course-slide'>
                            <img src='images/react.jpg' alt='#' />
                             <p>Frontend Reactjs</p>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='course-slide'>
                            <img src='images/laravel.jpg' alt='#' />
                             <p>Becom an Expert in laravel</p>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='course-slide'>
                            <img src='images/laptop.jpg' alt='#' />
                             <p>Laptop Troubleshooting - How to repair a system</p>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='course-slide'>
                            <img src='images/phone.jpg' alt='#' />
                             <p>Phone Troubleshooting - How to repair a phone</p>
                        </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='course-slide'>
                            <img src='images/computer-literacy.jpg' alt='#' />
                             <p>Computer literacy in microsoft word and Powerpoint</p>
                        </div>
                        </SwiperSlide>
                        
                    </Swiper>
                </div>

                <div class="attention">
                    <p><strong>NOTE:</strong> This option is not avalable at the moment. We will get back on it shortly. please do check back again</p>
                </div>
            </div>
        </div>
     </motion.section>
    </>
  )
}

export default Services;