import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn, slideIn, staggerContainer, textVariant } from '../../utils/motion';
import './about.css';

const About = () => {
  return (
    <>
      <motion.section 
      initial="hidden"
      whileInView="show"
      variants={staggerContainer}
      className='about-section' id='about'>
        <div className='container'>
            <h2 className='title'>Who are we?</h2>
            <p className='a-text'>We are software development company that specialize in creating software products and providing training and education in software development. Our goal as a software development company is to help individuals and organizations build their skills and capabilities in software development, create innovative software products that meets the needs of our clients.
</p>

            <div className='about-flex flex'>
              <motion.div
              variants={slideIn("up", "tween", 0.2, 1)}
              className='flex-box-content'>
                <img src="images/development.png" alt="development" />
                <h3>Development Solution</h3>
                <p>We are a company that helps organizations design, build and maintain software applications, we work with variety of clients, including businesses, government agencies, and individual consumers. Toverbs employ agile methodologies to rapidly illitrate and deliver high-quality software products to clients </p>
              </motion.div>

              <motion.div
              variants={slideIn("up", "tween", 0.4, 1)} 
              className='flex-box-content'>
                <img src="images/education.png" alt="education" />
                <h3>Software Academy</h3>
                <p>Toverbs software Academy offers training and education in software development. we offer coures that teaches the student the skills needed to become software developers. These programs covers the topics such as programming languages, software designs, web and mobile development methodologies, graphic designs. </p>
                </motion.div>

              <motion.div
              variants={slideIn("up", "tween", 0.6, 1)} 
              className='flex-box-content'>
                 <img src="images/consultancy.png" alt="consultant" />
                <h3>ICT Consultancy</h3>
                <p>We provide expert advice and assistance to businesses and organizations in the areas of Information and Communication Technology. This includes help with implementing new technologies, Troubleshooting existing systems and developing strategies for leveraging technology to achieve Business goals. </p>                 
              </motion.div>
            </div>

            <div className='image-text-div flex'>
                <div className='imagebox'>
                    <div className='green-div'>
                        <motion.div
                        variants={fadeIn("right", "tween", 0.2, 1)} 
                        className='image-div'>
                            <img src="images/about.jpg" alt="" />
                        </motion.div>
                    </div>
                </div>

                <motion.div
                className='textbox'>
                    <motion.p
                    variants={textVariant(0.5)} 
                    className='left-text'>
                    At Toverbs, we pride ourselves on being a full-service software development firm. Our team of experts handle everything from ideation and design to development and deployment. You need a simple website or a sophisticated software systems, we have the tools and expertise to make it happen. we work with range of industries and have track record of delivery successful projects for our Clients
                    </motion.p>

                    <motion.p
                    variants={textVariant(0.7)} 
                    className='left-text'>
                    We provide advice and assistance to organizations on how to use software to meet their specific needs. This include helping the organization choose the right software for their needs, designing custom solutions and providing guidance on how to implement and maintain software systems.
                    We are that organization that work closely with our clients to understand their business goals and needs and also develop strategies to use the softwares to meet goals
                    </motion.p>
                </motion.div>
            </div>
        </div>
      </motion.section>
    </>
  )
}

export default About;