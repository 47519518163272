import React from 'react';
import './consult.css'
import { Link } from 'react-scroll';

const Consult = () => {
  return (
    <>
    <section className='consult-section'>
        <div className='container'>
            <div className='flex'>
                <div className='c_left'>
                    <img src='images/logo.png' alt='#' />
                    <h3>Upscale your Business with Toverbs</h3>
                    <p>We assist businesses in enhancing their technology capabilities, utilizing technology to accomplish strategic goals, improving productivity, and strengthening their competitive edge. Our consultancy services encompass a broad range of services, including developing technology strategies, analyzing and designing systems, managing projects, assessing risks, developing software, and managing IT infrastructure.</p>
                <div className='btn-div'>
                <Link to="contact"><button className='button'>Consult us now</button></Link>
                <Link to="services"><button className='button-outline'>learn more</button></Link>
                </div>
                </div>

                <div className='c_right'>
                    <img src='images/consult.jpg' alt='#' />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Consult;