import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css'
import SuccessModal from './SuccessModal';

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_p7zlhfa', 'template_ka3eluu', form.current, '9Is0OXpvhO7dmdXSe')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset(e);
      setOpenModal(true);
  };

  const [openModal, setOpenModal] = useState(false);


  return (
    <>
      <section className='contact-section' id='contact'>
        <div className='container'>
            <div className='flex'>
                <div className='contact-left'>
                  <h2>Contact us now!</h2>
                  <h4>Fill up the Form and our Team will get back <br/>to you withing 24 hours</h4>
                  <div className='contact-details'>
                    <div className='c-details-item'>
                        <img src='images/call.png' alt='#' />
                        <p>+234 70 85027790</p>
                    </div>

                    <div className='c-details-item'>
                        <img src='images/mail.png' alt='#' />
                        <p>contactus@toverbs.com</p>
                    </div>

                    {/*<div className='c-details-item'>
                        <img src='#' alt='#' />
                        <p>Phone</p>
                    </div>*/}

                  </div>
                </div>

                <div className='contact-right'>
                 <form ref={form} onSubmit={sendEmail}>
                    <input type="text" placeholder='First name' name="user_firstname" required/>
                    <input type="text" placeholder='Last name' name="user_lastname" required/>
                    <input type="email" placeholder='Email' name="user_email" required/>
                    <input type="phone" placeholder='Phone no' name="user_phone" required/>
                    <textarea placeholder='Message' name="user_message"></textarea>
                    <button type='submit' className='button'>Submit</button>
                 </form>
                 <SuccessModal open={openModal} onClose={() => setOpenModal(false)} />
                </div>
                
            </div>
        </div>
      </section>
    </>
  )
}

export default Contact;