import React from 'react';
import { footerVariants, staggerChildren, zoomIn } from '../../utils/motion';
import { motion } from 'framer-motion';
import './footer.css';
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <>
      <motion.section 
      initial="hidden"
      whileInView="show"
      variants={staggerChildren} 
      viewport={{once: false, amount: 0.25}}
      className='footer-section'>

        <motion.div
        variants={footerVariants} 
        className='container'>

            <motion.div
            variants={zoomIn} 
            className='newsletter-div'>
              <h4>Sign up for our Newsletter</h4>
              <p>Get updates from us</p>
              <div className='newsletter-form'>
                <div className='n-input'>
                  <input type='text' placeholder='name@dmain.com' />
                </div>
                <div className='n-button'>
                  <button>Submit</button>
                </div>
              </div>
            </motion.div>
            <hr/>

            <div className='footer-menu'>
                <ul>
                  <li><Link activeClass="active" to="hero" spy={true} smooth={true} offset={-50} duration={500}>Home</Link></li>
                  <li><Link activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={500}>About us</Link></li>
                  <li><Link activeClass="active" to="services" spy={true} smooth={true} offset={-50} duration={500}>Services</Link></li>
                  <li><Link activeClass="active" to="clients" spy={true} smooth={true} offset={-50} duration={500}>Our Work</Link></li>
                  <li><Link activeClass="active" to="contact" spy={true} smooth={true} offset={-50} duration={500}>contact us</Link></li>
                    {/*<li>Terms & Condition</li>
                    <li>Legal stuffs</li>*/}
                </ul>

                <p className='copyright'>Copyright © 2024 Toverbers Ltd. All rights reserved.</p>
            </div>
        </motion.div>
      </motion.section>
    </>
  )
}

export default Footer;