import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn, staggerContainer } from '../../utils/motion';
import './hero.css'
import { Link } from 'react-scroll';

const Hero = () => {
  return (
    <>

     <section class="hero-section" id='hero'>
        <div className='container'>
          <div className='flex'>
            <motion.div 
            initial="hidden"
            whileInView="show"
            variants={staggerContainer}
            className='left'>
                <motion.h2 
                 variants={fadeIn("right", "tween", 0.2, 1)}
                className='hero-title'>TOVERBERS</motion.h2>

                <motion.p
                variants={fadeIn("right", "tween", 0.2, 1.2)}
                className='text'>We are a team of dedicated developers who are passionate about building custom softwares solutions that help Businesses Thrive. </motion.p>
                <motion.p
                variants={fadeIn("right", "tween", 0.2, 1.4)} 
                className='text'>We offer a wide range of services to help our clients bring their vision to life. Whether you need a website, mobile apps, cloud-based systems, or want to learn programming languages, we have the skills and resources to make it happen</motion.p>
                <Link to="services"><button className='button'>Learn more</button></Link>
            </motion.div>

            <motion.div
            initial="hidden"
            whileInView="show"
            variants={fadeIn("left", "tween", 0.2, 1)}  
            className='right'>
                <img src="images/heroRight.png" alt="tech" />
            </motion.div>
          </div>
        </div>
     </section>
    </>
  )
}

export default Hero;