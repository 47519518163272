import React from 'react';
import './clients.css'

const Clients = () => {
  return (
    <>
      <section className='clients-section' id='clients'>
        <div className='container'>
            <h2 className='title'>Our successful Clients</h2>
            <p className='client-p'>We have a track record of delivering high-quality and innovative projects that drive business growth and success. Our work include wide variety of industries and sectors such as Online shops, healthcare, retail, education etc. At Toverbs, we work closely with our clients to understand their unique needs and tailor our services to best meet those needs. We pride ourselvies on our ability to deliver projects on time and within budget </p>
            
            <div className='clients-list'>
              <div className='client'>
                <img src='images/360creative.png' alt='#' />
              </div>

              <div className='client'>
                <img src='images/nya.png' alt='#' />
              </div>

              <div className='client'>
                <img src='images/traversityusa.png' alt='#' />
              </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Clients;