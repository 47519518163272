import React from 'react';
import { Helmet } from 'react-helmet-async';
import About from '../../components/about/About';
import Clients from '../../components/clients/Clients';
import Consult from '../../components/consult/Consult';
import Contact from '../../components/contact/Contact';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Hero from '../../components/hero/Hero';
import Services from '../../components/services/Services';
import './home.css'

const Home = () => {
  return (
    <>

    <Helmet>
      <title>Toverbs</title>
      <meta name="description" content="An ICT company that develop softwares and mobile applications, software academy and an ICT consultation services." />
      <link rel="canonical" href="https://toverbs.com/"></link>
    </Helmet>


      <Header />
      <Hero />
      <About />
      <Services />
      <Consult />
      <Clients />
      <Contact />
      <Footer />
    </>
  )
}

export default Home;