import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home/Home';

function App() {
  return (
    <>
      <BrowserRouter>
        {/*<Header />*/}
         <Routes>
          <Route path="/" element={<Home/>}/>
          
        </Routes>
        {/*<Footer />*/}
      </BrowserRouter>

      
    </>
  );
}

export default App;
