import React, { useState } from 'react';
import { FaAlignRight, FaTimes } from 'react-icons/fa';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import './header.css'
import { headerVariants } from '../../utils/motion';

const Header = () => {

  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)

  const [color, setColor] = useState(false)
  const [imageSize, setImgSize] = useState(false)

  const ScrollChange = () =>{
    if(window.scrollY >=150){
      setColor(true)
      setImgSize(true)
    }else{
      setColor(false)
      setImgSize(false)
    }
  }

  window.addEventListener('scroll', ScrollChange)

  const closeMenu = () => setClick(false)

  return (
    <>

<div class="mobile-div">
<Link activeClass="active" to="hero" spy={true} smooth={true} offset={-50} duration={500}><img class="mobile-logo" src="images/logo.png" alt="Logo" /></Link>
      <div onClick={handleClick}>
      {click ? (<FaTimes size={25} style={{color: '#fff'}} />) : (<FaAlignRight size={25} style={{color: '#fff'}} />)}
      </div>
      
      <div className={click ? "mobile-menu active" : "mobile-menu"}>
      <ul>
          {/*<li><Link activeClass="active" to="hero" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>Home</Link></li>*/}
          <li><Link activeClass="active" to="hero" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>Home</Link></li>
          <li><Link activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>About us</Link></li>
          <li><Link activeClass="active" to="services" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>Services</Link></li>
          <li><Link activeClass="active" to="clients" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>Our Work</Link></li>
          
          <li><Link activeClass="active" to="hero" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>Mission and Vision</Link></li>
          
          <li><Link activeClass="active" to="contact" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>contact us</Link></li>
      </ul>
      </div>
      </div>
      

     <header
      
     className={color ? "header header-bg" : "header"}>
        <motion.div
        initial="hidden"
        whileInView="show"
        variants={headerVariants} 
        viewport={{ once: true, amount: 0.25 }} 
        className='container header-flex'>
        <div className='logo-div'>
            <img className={imageSize ? "small-logo" : "logo"} src="images/logo.png" alt="logo" />
        </div>
        <nav>
           <ul>
           <li><Link activeClass="active" to="hero" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>Home</Link></li>
          <li><Link activeClass="active" to="about" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>About us</Link></li>
          <li><Link activeClass="active" to="services" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>Services</Link></li>
          <li><Link activeClass="active" to="clients" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>Our Work</Link></li>
          
          {/*<li><Link activeClass="active" to="hero" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>Mission and Vision</Link></li>*/}
          
          <li><Link activeClass="active" to="contact" spy={true} smooth={true} offset={-50} duration={500} onClick={closeMenu}>contact us</Link></li>
           </ul>
        </nav>
        </motion.div>
     </header>
    </>
  )
}

export default Header;