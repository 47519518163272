import React from 'react';
import { FaRegTimesCircle } from 'react-icons/fa';

const SuccessModal = ({ open, onClose }) => {
    if (!open) return null;
  return (
    <>
      <div div onClick={onClose} className='overlay'>
        <div className='modalContainer'>
            <h2>Thank you for reaching out to us!.</h2>
            <p>Our Representative will get to you shortly</p>
            <FaRegTimesCircle class="closeIcon" onClick={onClose} size={25} style={{color: '#d4b037'}} />
        </div>
        
      </div>
    </>
  )
}

export default SuccessModal;